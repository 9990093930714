// src/components/About.js

import React from 'react';

const About = () => {
  return (
    <div>
      <h2>About Us</h2>
      <p>BaneyMarket is committed to providing the best stock trading experience.</p>
    </div>
  );
};

export default About;
